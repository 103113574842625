@keyframes moveBg {
    0% {
        background-position: 0 0;
      }
      50% {
        background-position: 20% 20%;
      }
      100% {
        background-position: 0 0;
      }
  }
  
  .animatedBackground {
    animation: moveBg 15s linear infinite;
  }
  

.carousel-container{

    overflow: hidden;
}

.carousel-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
}

.slick-track {
  display: inline-flex;
  gap: 20px;
  margin-left: -20px;
}

.carousel-content {
  background-color: #ffffff20;
  backdrop-filter: blur(50px);
  padding: 20px;
  border-radius: 15px;
}

.link-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  transition: 0.5s;
}

.slick-next{

    display: none;
}

.slick-prev{

    display: none;
}

.carousel-body:hover .link-icon {
  transform: scale(1.2);
  transition: 0.5s;
  rotate: -45deg;
}

.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.tag_badge {
  font-size: 13px;
  font-weight: 300;
  text-transform: none;
  line-height: 1.5em;
  background-color: #ffffff1a;
  color: #ffffff;
  padding: 5px 10px;
  margin: 2px;
  border-radius: 5px 5px 5px 5px;
}
