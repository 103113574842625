
.service-grid-container {
    display: grid;
    transition: 1s;
    gap: 10px;
}
.service-grid-item {
    color: #1f1f1f;
    font-size: 1.5rem;
    align-content: flex-end;
    transition: 1s;
    height: 160px;
    margin: 0px 0px 0px 0px;
    padding: 20px 10px 20px 10px;
    background-color: #FBFBFB;
    border-radius: 10px 10px 10px 10px;
}

/* Default layout: 6 columns */
.service-grid-container {
    grid-template-columns: repeat(4, 1fr);
    transition: 1s;
}

.services-icon-box-title{

    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;

}

.services-icon-box-icon{

    width: 50px;
    height: auto;
    padding-bottom: 20px;
}

/* Responsive layout: 3 columns on smaller screens */
@media (max-width: 1254px) {
    .service-grid-container {
        grid-template-columns: repeat(3, 1fr);
        transition: 1s;
    }
}

@media (max-width: 600px) {
    .service-grid-container {
        grid-template-columns: repeat(2, 1fr);
        transition: 1s;
    }
    
    .service-grid-item {
        height: 140px;
    }

    .services-icon-box-title{
        font-size: 14px;
    }

    .services-icon-box-icon{
        width: 40px;
        padding-bottom: 10px;
    }

    
}

