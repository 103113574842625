.elementor-button {
    font-family:"Poppins", Sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    fill: #fff;
    color: #fff;
    background-color: #000;
    border-radius: 0px 0px 0px 0px;
    padding: 10px 10px 10px 20px;

    background: linear-gradient(to right, #f8a925 52%, #ffffff00 50%);
    background-size: 200% 110%;
    background-position: right bottom;
    transition: all 0.6s ease;
    text-decoration: inherit;

    width: 100% !important;
    text-align: left;
}

.elementor-button-light {
    font-family:"Poppins", Sans-serif;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    fill: #000000;
    color: #000000;
    background-color: #000;
    border-radius: 0px 0px 0px 0px;
    padding: 10px 10px 10px 20px;

    background: linear-gradient(to right, #f8a925 52%, #ffffff00 50%);
    background-size: 200% 110%;
    background-position: right bottom;
    transition: all 0.6s ease;
    text-decoration: inherit;

    width: 100% !important;
    text-align: left;
}

/* .elementor-button .elementor-align-icon-right {
    margin-left: 10px;
} */


.elementor-align-icon-right {
    margin-left: 5px;
    order: 15;
}

/* .elementor-button-icon {
    flex-grow: 0;
    order: 5;
} */


.elementor-button-text {
    flex-grow: 1;
    order: 10;
    display: inline-block;
    margin-right: 40px;
}

.elementor-button-icon svg {
    width: 1em;
    height: auto;
}

.button_icon_{
    transition: 0.6s;

}

.elementor-button:hover .button_icon_, .elementor-button:focus .button_icon_ {
    fill: #1f1f1f;
    transform: rotate(45deg);
    transition: 0.6s;
}

.elementor-button-light:hover .button_icon_, .elementor-button-light:focus .button_icon_ {
    fill: #1f1f1f;
    transform: rotate(45deg);
    transition: 0.6s;
}

.elementor-button-light:hover {
    color: #1f1f1f !important;
    /* color: #336 !important; */
    background-position: left bottom;
}



.elementor-button:hover {
    color: #1f1f1f !important;
    /* color: #336 !important; */
    background-position: left bottom;
}


/* .elementor-button-content-wrapper {
    display: flex;
    justify-content: center;
} */

/* .elementor-button:hover, .elementor-button:focus {
    
} */
.elementor-button-wrapper{
    transition: 0.6s;
}

.elementor-button-wrapper:hover{
    transform: translateX(10px);
    transition: 0.6s;
}

   /* Tablet CSS */

   @media only screen and (min-width: 768px) and (max-width: 1024px){

    .elementor-button, .elementor-button-light{

        font-size:18px;
    }

   }


      /* Mobile CSS */

      @media only screen and (max-width: 767px){

        .elementor-button, .elementor-button-light{

            font-size:16px;
        }

      }