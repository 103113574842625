.slides {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  padding-left: 0px;
  overflow: hidden;
}
.slide {
  list-style-type: none;
  height: 400px;
  width: 5%;
  overflow: hidden;
  transition: width 0.6s ease-in-out;
}

.slide a.action {
  display: block;
  height: 100%;
  position: relative;
  background-color: #dcdcdc;
  padding: 10px;
  -webkit-box-shadow: 5px 6px 25px 5px rgba(220, 220, 220, 0.25);
  -moz-box-shadow: 5px 6px 25px 5px rgba(220, 220, 220, 0.25);
  box-shadow: 5px 6px 25px 5px rgba(220, 220, 220, 0.25);
}
.slide a.action span {
  font-weight: 600;
  color: #000;
  transform: rotate(270deg);
  position: absolute;
  top: 20%;
  width: 300px;
  left: -150%;
  height: 100px;
  font-size: 18px;
}
.slide-content {
  display: block;
  height: 100%;
  width: 100%;
  padding: 40px;
  color: #fff;
  background: #1f1f1f;
  opacity: 0;
  transition: opacity 0.9s ease-in-out, padding 0.6s ease-in-out;
}
.slides-active .slide-content {
  opacity: 1;
  transition: opacity 2s ease-in-out, padding 2s ease-in-out;
}
.slide-button {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  fill: #000000;
  color: #ffffff;
  background-color: #000;
  border-radius: 0px;
  padding: 10px 10px 10px 20px;
  background: linear-gradient(to right, #f8a925 52%, #ffffff00 50%);
  background-size: 200% 110%;
  background-position: right bottom;
  transition: all 0.6s ease;
  text-decoration: inherit;
  width: 200px;
  text-align: left;
  display: inline-block;
  position: relative;
  bottom: -30px;
  left: 80.5%;
}

.slide-button:hover {
  background-position: left bottom;
}
.slides-active {
  width: 100%;
}
.slides-active a.action {
  display: none;
}
.slide-content h3 {
  margin-top: -60px;
}
.service-id {
  position: relative;
  top: -50px;
  left: -30px;
  color: #dcdcdc;
  font-size: 60px;
  font-weight: 800;
  opacity: 0.3;
}

.gf_h2::before {
  content: "";
  display: block;
  position: relative;
  width: 40px;
  height: 5px;
  background-color: #1f1f1f;
  left: -50px;
  top: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .slides {
    flex-direction: column;
    row-gap: 10px;
  }
  .slide {
    width: 100%;
    height: auto;
  }

  .slide a.action {
    padding: 20px;
  }
  .slide a.action span {
    transform: rotate(0deg);
    top: 50%;
    left: 10%;
    width: auto;
    height: auto;
    transform: translateY(-50%);
  }
  .slides-active {
    width: 100%;
  }
  .slide-content {
    width: 100%;
    padding: 0px;
  }
  .slides-active .slide-content {
    padding: 20px;
  }
  .service-id {
    position: relative;
    top: -20px;
    left: -10px;
    color: #dcdcdc;
    font-size: 32px;
    font-weight: 800;
    opacity: 0.3;
  }
  .slide-content h3 {
    margin-top: -20px;
  }

  .slide-button {
    font-size: 14px;
    padding: 10px 10px 10px 20px;
    position: relative;
    bottom: -15px;
    width: 160px;
    left: 64%;
  }

  .gf_h2::before {
    width: 20px;
    height: 5px;
    left: -20px;
  }
}
