.FloatingButton img {
    position: fixed;
    animation: rotating 10s linear infinite;
    z-index: 100;
    bottom: 10px;
    right: 10px;
    width: 70px;
    height: 70px;
}

@keyframes rotating {
    0% {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    
    100% {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 767px){

    .FloatingButton img {
        position: fixed;
        animation: rotating 10s linear infinite;
        z-index: 100;
        bottom: 10px;
        right: 10px;
        width: 60px;
        height: 60px;
    }

}