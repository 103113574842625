.formSlider {
  position: fixed;
  background-color: #ffffff;
  z-index: 100;
  top: 1300px;
  height: 100%;
  left: 0;
  right: 0;
  display: block;
  overflow: auto;
}
.formSlider::-webkit-scrollbar {
  display: none;
}

.select-image,
.radio-image {
  width: 100%;
  border: 1px solid #000;
  cursor: pointer;
}

.box {
  padding: 10px 0;
}

.form-h6 {
  font-size: 18px;
  font-weight: 600;
}

.form-h1 {
  font-size: 56px;
  font-weight: 600;
  line-height: 1.2em;
}

.form-p {
  font-weight: 300;
  letter-spacing: 0.3px;
}

.form-h3 {
  font-weight: 600;
}

label {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 5px;
}

.form-control,
.form-select {
  border-left: 4px solid #f8a925;
  border-bottom: 1px solid #f8a925;
  border-radius: 0;
  border-top: 0;
  border-right: 0;
  height: 50px;
  font-size: 14px;
}

.form-control:focus,
.form-select:focus {
  box-shadow: none;
  border-color: #f8a925;
}

.submit-btn {
  background-color: #000000;
  border: none;
  color: #fff;
  padding: 10px 40px;
  transition: all 0.5s ease-out;
}

.submit-btn:hover {
  background-color: #f8a925;
}

.active-border {
  border: 3px solid #f8a925;
  /* border-radius: 7px; */
}

.inactive-border {
  border: 1px solid #000;
}

.url {
  font-weight: 300;
}

.close-btn {
  cursor: pointer;
}

.grid-container-2 {
  display: grid;
  transition: 1s;
  gap: 10px;
  grid-template-columns: repeat(3, 1fr);
}

.form-show {
  display: block;
  animation: top 0.5s;
  top: 0;
  background-color: #00000070;
}

@keyframes top {
  from {
    opacity: 0;
    top: -60px;
  }
  to {
    opacity: 1;
    top: 0;
  }
}

.hide {
  display: none;
  animation: bottom 0.5s;
}

@keyframes bottom {
  from {
    opacity: 1;
    top: 0;
    display: block;
  }
  to {
    opacity: 0;
    top: -60px;
    display: none;
  }
}

@media (max-width: 700px) {
  .grid-container-2 {
    grid-template-columns: repeat(2, 1fr);
    transition: 1s;
  }

  label {
    font-size: 16px;
  }
}
