.FlipBox {
  padding: 20px;
  background-color: rgba(97, 97, 97, 0.247);
  animation: autoflip 5s infinite;
  position: absolute;
  z-index: 9;
  border-style: none;
  border-radius: 10px;
  width: 120px;
  height: 120px;
}

.FlipBox_position_1 {
  top: 20%;
  left: 20%;
}

.FlipBox_position_2 {
  right: 8%;
}

.FlipBox_position_3 {
  bottom: 10%;
  left: 20%;
}

.social-icon {
  color: #fff;
  font-size: larger;
}

.social-icon:hover {
  color: #f8a925;
}

@keyframes autoflip {
  0% {
    transform-style: preserve-3d;
    transform: rotateY(0);
  }
  50% {
    transform-style: preserve-3d;
    transform: rotateY(180deg);
  }
  100% {
    transform-style: preserve-3d;
    transform: rotateY(0);
  }
}

.flip-box {
  /* background-color: transparent; */
  /* width: 300px;
    height: 200px; */
  perspective: 1000px;
  animation: autoflip 5s infinite;
  position: absolute;
  z-index: 9;
  width: 130px;
  height: 100px;
}

.flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;

  transition: transform 0.8s;
  transform-style: preserve-3d;
  /* 
    animation: autoflip 5s infinite;
    animation-play-state: running; 
    */
}

.flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);

  /* animation-play-state: paused;  */
}

.flipbox-num {
  font-size: 40px;
  font-weight: bold;
}

.flipbox-text {
  font-size: 16px;
  line-height: 1.3;
  font-weight: 400;
  padding-bottom:12px;
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 10px;

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-box-front {
  border-style: none;
  border-radius: 10px;
  background-color: rgba(97, 97, 97, 0.247);
  color: rgb(255, 255, 255);
}

.flip-box-back {
  border-style: none;
  border-radius: 10px;
  background-color: rgba(97, 97, 97, 0.247);
  color: rgb(255, 255, 255);
  transform: rotateY(180deg);
}

@media (max-width: 768px) {
  .FlipBox_position_1 {
    top: 10%;
    left: 10%;
  }

  .FlipBox_position_2 {
    right: 2%;
    bottom: 50%;
  }

  .FlipBox_position_3 {
    bottom: 10%;
    left: 10%;
  }

  .flip-box {
    width: 90px;
    height: 90px;
  }

  .flipbox-num {
    font-size: 20px;
  }

  .flipbox-text {
    font-size: 12px;
  }
}
