

.HeroSection{
  background-color: #1F1F1F;
  color:#fff;
  height: 100vh;
  max-height: 100vh;
  /* min-width: 100vh; */
  /* margin-top: 50px; */
}

.Section_White{
  color:#fff;
  height: 100vh;
  max-height: 100vh;
  /* min-width: 100vh; */
  margin-top: 50px;
}

.HeroSection_Title{
  color: #FFFFFF;
  font-family: "Poppins", Sans-serif;
  font-size: 60px;
  font-weight: bold;
  line-height: 1.4em;
  letter-spacing: 1px;
}

.verticle_center_heroSection_left{
  display: flex;
  flex-direction: column;       
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: left;
}

.verticle_center_heroSection_right{
  display: flex;
  padding-top: 100px;
  flex-direction: row;       
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.animation-wrapper {
  width: 80%;
  /* padding-bottom: 40%;
  z-index: -1; */
}



/* .sphere-animation {
  
  position: absolute;
  top: 50%;
  left: 50%;
  width: 480px;
  height: 480px;
  margin: -240px 0 0 -240px;
} */

.sphere path {
  fill: url(#sphereGradient);
  stroke-width: 1px;
  stroke: rgba(80,80,80,.35);
  backface-visibility: hidden;
}



@media (min-width: 500px) {
  .sphere path {
    stroke-width: .4px;
  }
}

@media(max-width: 768px){
     .animation-wrapper {
      width: 110%;
      padding-bottom: 10%;
    } 

    .hideElementInPhone{
      display: none;
    }

    .centerElements{
      text-align: center !important;
    }

    .verticle_center_heroSection_right{
        padding-top: 20px;
      }


}





.test_{
  color: red;
}

.wodo_yellow{
  color:#f8a925;
}

.Heading_title{
  color:#fff;
  font-family: "Poppins", Sans-serif;
  font-size: 44px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1;
  text-align: left;
}

.Heading_title-black{
  color:#1f1f1f;
  font-family: "Poppins", Sans-serif;
  font-size: 2.4rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1;
}

.awardsBackground{
  min-height: 30vh;
  background-color: #1F1F1F;  
  color:#fff;
  padding-bottom: 50px;
}

.awards_circle img{
  animation: rotating 10s linear infinite;
}


@keyframes rotating {
  0% {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

.verticle_center{
  display: flex;
  flex-direction: column;       
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.element_tab_button-active{
  color: #1f1f1f;
  background-color: transparent;
  background-image: linear-gradient(90deg, #f8a925 0%, #00000000 100%);
  border-radius: 15px 10px 10px 15px !important;
  transition: 0.5s;

}

.element_tab_button{
  color: #1f1f1f;
  font-size: 14px;
  line-height: 1.5em;
  border-radius: 15px 10px 10px 15px !important;
  cursor: pointer;
  transition: 0.5s;
  margin-top: 10px;
}

.element_tab_button:hover{
  color: #1f1f1f;
  background-color: transparent;
  background-image: linear-gradient(90deg, #f8a925 0%, #00000000 100%);
  border-radius: 15px 10px 10px 15px !important;
  transform: translateX(10px);
  transition: 0.5s;
}

.tab_num{
  transform-origin: 0 0;
  transform: rotate(270deg);
  position:relative ;
  margin-right:10px;
  font-size: 9px;
  font-family: Poppins, Sans-serif;
  font-weight: 600;
  top:16px;
}


.glow_circle_1{
  background-color: transparent;
  background-image: radial-gradient(at center center, #F8A9256B 0%, transparent 67%);
  width: 100px;
  height: 100px;

  position: absolute;
  top: -143.958px;
  /* left: -471.979px; */
  left: -250px;
  width: 400px;
  height: 400px;
  flex-grow: 0;
  --spacer-size: 500px;
  z-index: 99;
  transition: 0.5s;
}

.glow_circle_2{
  background-color: transparent;
  background-image: radial-gradient(at center center, #F8A9256B 0%, transparent 60%);
  width: 100px;
  height: 100px;

  position: absolute;
  bottom: -10px;
  right: 0px;
  width: 400px;
  height: 400px;
  flex-grow: 0;
  --spacer-size: 400px;
  transition: 0.5s;

  /* z-index: 9; */
}

.footer_align_list{
  display: flex;
  flex-direction: row-reverse;

}

@media screen and (max-width: 767px) {
  .glow_circle_1,.glow_circle_2{
    width: 150px;
    height: 150px;
  }
  .footer_align_list{
    flex-direction: row;
  }

  .HeroSection{
    height:95vh;
  }
}