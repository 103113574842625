.slider-image{

    width:100%;
    height: 100%;
    border-radius: 10px;
}

/* .slider-container{

    overflow: hidden;
} */