.services-hero{

    height: 60vh;
    background-color: #1f1f1f;
    align-items: center;
    display: flex;
    flex-direction: row;
}

.service-details{

    background-image: url("https://server-demo.wodo.digital/media/2022/03/WODO-about-page-gradient-1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.services-h5-light, .services-h3{

    color: #fff;
}

.number{

    font-size: 68px;
    font-weight: 700;
}

.services-list li{
    font-weight: 500;
}

.services-h4{
    font-size: 24px;
    font-weight: 600;
    color: #1f1f1f;
    width: 60%;
}

.services-h5-light::before{
    
    content: "";
    width: 40px;
    height: 3px;
    background-color: #fff;
    display: block;
    position: relative;
    top: 6px;
    left: -45px;
}

.services-h5-dark::before{
    content: "";
    width: 40px;
    height: 3px;
    background-color: #1f1f1f;
    display: block;
    position: relative;
    top: 6px;
    left: -45px;
}

.services-h2::before{
    content: "";
    display: block;
    position: relative;
    width: 40px;
    height: 5px;
    background-color: #1f1f1f;
    left: -50px;
    top: 10px;
}

.number-box{

    border: 1px solid #f5f5f5;
}

.number-box-number{

    color: #F8A925;
    font-size: 68px;
    font-weight: 700;
}
/* Tabet view */
@media (max-width: 1254px){

    .number-box-number{
        font-size: 42px;
    }


}

/* Mobile view */

@media (max-width: 600px){

    .services-h4{

        font-size: 20px;
        width: 100%;
    }

    .services-hero{

        height: 35vh;
    }

    .services-h5-light::before{

        left:-15px;
        width: 15px;
    }

    .services-h5-dark::before{

        left:-15px;
        width: 15px;
    }

    .number{

        font-size: 48px;
    }

    .services-h2::before{
        left: -25px;
        width: 20px;
    }

    .number-box-number{

        font-size: 32px;
    }
}